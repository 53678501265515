import React from "react";
import styled from "styled-components";
import Section from "../section";
import CTAGreen from "../cta/ctagreen";
//import Animation from "../animation"
import banner from "../../images/banner.jpg";
import SbEditable from "storyblok-react";
import ReactMarkDown from "react-markdown";


const Anchor = styled.a`
  display: block;
  position: relative;
  visibility: hidden;
  @media screen and (max-width: 991px) {
    top: -70px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  @media screen and (max-width: 767px) {
    padding: 60px 0;
  }
  @media screen and (max-width: 575px) {
    padding: 60px 34px;
  }
`;


const CTAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled("h1")`
  margin: 0px auto 0px;
  max-width: 900px;
  text-align: center;
  font-size: 50px;
  text-shadow: 1px 1px 2px black;
  color: #fff;
  padding-top: 15vh;
  @media screen and (max-width: 767px) {
    padding-top: 0;
    font-size: 36px;
  }
  @media screen and (max-width: 350px) {
    padding-top: 0;
    margin-top: -10vh;
    font-size: 36px;
  }
`;
const Subtitle = styled.div`
  margin: 0 auto;
  text-align: center;
  text-shadow: 1px 1px 2px black;
  color: #fff;
  p {
    display: block;
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: 400;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 32px;
  }
  @media screen and (max-width: 767px) {
    margin: 0 auto;
    width: 50%;
  }
`;
const Fleche = styled("img")`
  margin: 0vh auto 0;
  width: 25%;
  @media screen and (max-width: 767px) {
    width: 60%;
  }
  @media screen and (max-width: 350px) {
    width: 60%;
  }
`;

const Banner = props => (
  <SbEditable content={props.blok}>
    <Anchor class="anchor" id="banner"></Anchor>
    <Section imageSrc={banner} css="scroll-snap-align: start;" data-scroll-class="scroll" data-section-name="accueil">
      <Wrapper>
        <Title
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in"
          data-aos-delay="200">{props.blok.title}</Title>
        <Fleche
          src={require("../../images/baisse-des-prix-2025.png")}
          alt="baisse des prix en 2025"></Fleche>
        <CTAWrapper>
          <Subtitle
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in"
            data-aos-delay="250">
            <ReactMarkDown source={props.blok.subtitle} />
          </Subtitle>

          <CTAGreen
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in"
            data-aos-offset="0"
            data-aos-delay="300"
            to="situation"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            buttonwidth="309px"

          >
          {props.blok.cta_btn_name}
          </CTAGreen>
        </CTAWrapper>
      </Wrapper>
    </Section>
  </SbEditable>
);

export default Banner;
